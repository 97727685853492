@import "modules/variables.scss";

.re-error-page {

	.re-entry-form-wrapper {
		color: $color-black;
		text-align: center;
		max-width: none;
	}

	p {
		margin-top: 0;
	}

	.re-error-title {
		margin-bottom: 0;
	}

	.re-error-page-items {
		list-style-type: none;
		padding: 0;

		li {
			display: inline-block;
			margin-top: 10px;
			padding: 0 20px;
		}

		img {
			height: 60px;
			margin-bottom: 20px;
		}

		.re-link-items {
			text-decoration: underline;
		}
	}

	.re-entry-alterate {
		max-width: 350px;
	}
}
